import { GET_TOKEN, GET_USER_DETAILS, GET_USER_DETAILS_FROM_LOCAL, SIGNOUT, SIGNUP, TOKEN_ATTEMPT_ERROR } from '../actions/types';

const initialState = {
	token: {
		refresh: '',
		access: '',
	},
	role: '',
	email: '',
	is_email_verified: false,
	typeId: '',
	id: '',
	first_name: '',
	last_name: '',
	errorMessage: '',
	zip_code: '',
	phone: '',
	loading: false,
	investors_number: 0,
	bought_investor_list: [],
	listings: [],
	selectedListing: null,
};

const authReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_TOKEN:
			const { refresh, access } = payload;

			return { ...state, token: { access, refresh }, errorMessage: '' };
		case GET_USER_DETAILS:
			const { id, first_name, last_name, email, role } = payload;
			localStorage.setItem('details', JSON.stringify(payload));
			return {
				...state,
				id,
				first_name,
				last_name,
				email,
				role,
				errorMessage: '',
			};
		case 'UPDATE_INVESTOR_COUNT':
			return { ...state, ...payload };
		case 'UPDATE_AGENT_COUNT':
			return { ...state, ...payload };

		case 'GET_TYPE_ID':
			const { id: typeId, investors_number, bought_investor_list } = payload;
			return { ...state, typeId, investors_number: investors_number, bought_investor_list: bought_investor_list };

		case GET_USER_DETAILS_FROM_LOCAL:
			const { id: id1, first_name: first_name1, last_name: last_name1, email: email1, type: type1, atoken, rtoken, is_email_verified } = payload;
			return {
				...state,
				id: id1,
				first_name: first_name1,
				last_name: last_name1,
				email: email1,
				type: type1,
				is_email_verified: is_email_verified,
				token: { access: atoken, refresh: rtoken },
				errorMessage: '',
			};

		case SIGNUP:
			const { id: id2, user, zip_code, phone } = payload;
			return {
				...state,
				typeId: id2,
				zip_code,
				phone,
				errorMessage: '',
				role: user.role,
				email: user.email,
				first_name: user.first_name,
				last_name: user.last_name,
			};

		case TOKEN_ATTEMPT_ERROR:
			return { ...state, errorMessage: payload };
		case SIGNOUT:
			return { ...initialState, errorMessage: '' };
		case 'STOP_LOADING':
			return { ...state, loading: false };
		case 'START_LOADING':
			return { ...state, loading: true };
		case 'UPDATE_NAME':
			return { ...state, first_name: payload.first_name, last_name: payload.last_name };
		case 'GET_LISTINGS':
			const selectedListing = payload?.[0] || null;
			const typeIden = selectedListing?.id;
			return { ...state, listings: payload, selectedListing, typeId: typeIden };
		case 'SET_LISTING':
			return { ...state, selectedListing: payload, typeId: payload?.id };
		case 'UPDATE_LISTING':
			const index = state.listings.findIndex((i) => i.id === payload.id);
			const listings = state.listings;
			if (index !== -1) {
				listings[index] = payload;
			}
			return { ...state, selectedListing: payload, typeId: payload?.id, listings };
		case 'ADD_LISTING':
			return { ...state, selectedListing: payload, typeId: payload?.id, listings: [payload, ...state.listings] };
		case 'noting':
			return state;
		default:
			return state;
	}
};

export default authReducer;
