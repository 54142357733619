import {
  Article,
  AssignmentInd,
  AttachMoney,
  Dashboard,
  Devices,
  Paid,
  PostAdd,
  QueryStats,
  RecentActors,
  Storefront,
  Style,
} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import { Alert, Badge } from "@mui/material";
import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Lockable from "../Lockable/Lockable";
import "./leftnavbar.scss";
import NavbarDropdown from "./NavDropDown";
import axios from "axios";
import { toast } from "react-toastify";

const AgentLeftNav = ({ onClose }) => {
  const Username = useSelector((state) => state.auth.first_name);
  const { premierAgentWebsite, dealAnalyzer, liveLeads, bpoFarm } = useSelector(
    (state) => state.subscriptions
  );

  const type = useSelector((state) => state.auth.role);
  const count = useSelector((state) => state.auth.investors_number);
  const email = useSelector((state) => state.auth.email);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.auth.token.refresh);

  const handleClick = async () => {
    if (!(premierAgentWebsite || dealAnalyzer || liveLeads || bpoFarm)) return;
    const url = process.env.REACT_APP_MARKETING_CENTER_URL;
    try {
      setIsLoading(true);
      const { data } = await axios.post(url, {
        token,
      });

      const frontendUrl = data?.data?.frontendUrl;
      const tokenToPass = data?.data?.token.access;

      window.open(
        `${frontendUrl}login/${tokenToPass}?email=${encodeURIComponent(email)}`,
        "_blank"
      );
    } catch (error) {
      toast.warn(error?.response?.data?.error || "An error occurred", {
        toastId: "errrror",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleMBAClick = async () => {
    if (!(premierAgentWebsite || dealAnalyzer || liveLeads || bpoFarm)) return;

    const url = process.env.REACT_APP_MBA_BUSINESS_PLAN_URL;
    try {
      setIsLoading(true);
      const { data } = await axios.post(url, {
        token,
      });

      const frontendUrl = data?.data?.frontendUrl;
      const tokenToPass = data?.data?.token.access;
      const urlS = `${frontendUrl}/${tokenToPass}?email=${encodeURIComponent(
        email
      )}`;
      window.open(urlS, "_blank");
    } catch (error) {
      toast.warn(error?.response?.data?.error || "An error occurred", {
        toastId: "errrror",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (type === "agent" || type === "realty_agent" || !type) {
    return (
      <div className="left_navbar">
        <h1 style={{ fontSize: "1.6rem", margin: "2rem 0" }}>
          Welcome, {Username}!
        </h1>
        <div className="nav_links_wrapper">
          <NavLink className="nav_link " to="agent-dashboard" onClick={onClose}>
            <Dashboard className="nav_icon" />
            <p>Dashboard</p>
          </NavLink>
          <NavLink
            className="nav_link "
            to="available-territories"
            onClick={onClose}
          >
            <AssignmentInd className="nav_icon" />
            <p>Subscribe</p>
          </NavLink>
          {/* <NavbarDropdown
						label={({ show }) => (
							<div className='nav_link'>
								
								
							</div>
						)}
						routesToCheck={['available-territories', 'allocated-territories', 'search-territories']}
					>
						<NavLink className='nav_link ' to='search-territories' onClick={onClose}>
							<p>Search Territories</p>
						</NavLink>
						<NavLink className='nav_link ' to='available-territories' onClick={onClose}>
							<p>Buy Territories</p>
						</NavLink>
						<NavLink className='nav_link ' to='allocated-territories' onClick={onClose}>
							<p>Allocated Territories</p>
						</NavLink>
					</NavbarDropdown> */}

          <NavbarDropdown
            label={({ show }) => (
              <div className="nav_link">
                <Article className="nav_icon" />
                <p>BPO Report</p>
                {show ? (
                  <FaChevronDown className="drop_icon" />
                ) : (
                  <FaChevronRight className="drop_icon" />
                )}
              </div>
            )}
            routesToCheck={[
              "customer-requested-report",
              "saved-reports",
              "completed-reports",
            ]}
          >
            <NavLink
              className="nav_link "
              to="customer-requested-report"
              onClick={onClose}
            >
              <p>Customer Requested Reports</p>
            </NavLink>
            <NavLink className="nav_link " to="saved-reports" onClick={onClose}>
              <p>Saved Reports</p>
            </NavLink>
            <NavLink
              className="nav_link "
              to="completed-reports"
              onClick={onClose}
            >
              <p>Completed Report</p>
            </NavLink>
          </NavbarDropdown>
          <Lockable subscribed={premierAgentWebsite} link="/prem-sub">
            <NavLink
              className="nav_link "
              to="premier-agent-website"
              style={{ pointerEvents: premierAgentWebsite ? "all" : "none" }}
              onClick={onClose}
            >
              <Devices className="nav_icon" />
              <p>Investor Website</p>
              {!premierAgentWebsite && (
                <LockIcon fontSize="16px" sx={{ ml: "auto" }} />
              )}
            </NavLink>
          </Lockable>
          <NavLink
            className="nav_link "
            to="agent-ordering-page"
            onClick={onClose}
          >
            <PostAdd className="nav_icon" />
            <p>My Ordering Page</p>
          </NavLink>
          <Lockable subscribed={liveLeads} link="/prem-sub">
            <NavLink
              className="nav_link "
              to="live-leads"
              style={{ pointerEvents: premierAgentWebsite ? "all" : "none" }}
              onClick={onClose}
            >
              <RecentActors className="nav_icon" />
              <p>Live Leads</p>
              {!liveLeads && <LockIcon fontSize="16px" sx={{ ml: "auto" }} />}
            </NavLink>
          </Lockable>
          {/* <NavLink className='nav_link ' to='live-leads'>
						<RecentActors className='nav_icon' />
						<p>Live Leads</p>
					</NavLink> */}
          <Lockable
            subscribed={!isLoading}
            link="/analytics"
            title="Click to upgrade to Deal Analyzer"
          >
            <NavbarDropdown
              label={({ show }) => (
                <div className="nav_link">
                  <QueryStats className="nav_icon" />
                  <p>Deal Analyzer</p>
                  {show ? (
                    <FaChevronDown className="drop_icon" />
                  ) : (
                    <FaChevronRight className="drop_icon" />
                  )}
                </div>
              )}
              routesToCheck={[
                "analyze-deal",
                "dealanalyzer-reports/uncompleted-reports",
                "dealanalyzer-reports/completed-reports",
              ]}
            >
              <NavLink
                className="nav_link "
                to="analyze-deal"
                onClick={onClose}
              >
                <p>Request Pro Forma Report</p>
              </NavLink>
              <NavLink
                className="nav_link "
                to="dealanalyzer-reports/uncompleted-reports"
                onClick={onClose}
              >
                <p>Uncompleted Reports</p>
              </NavLink>
              <NavLink
                className="nav_link "
                to="dealanalyzer-reports/completed-reports"
                onClick={onClose}
              >
                <p>Completed Reports</p>
              </NavLink>
            </NavbarDropdown>
            {/* <NavLink className='nav_link ' to='analyze-deal' style={{ pointerEvents: premierAgentWebsite ? 'all' : 'none' }}>
							<QueryStats className='nav_icon' />
							<p>Deal Analyzer</p>
							{!premierAgentWebsite && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
						</NavLink> */}
          </Lockable>
          <NavLink className="nav_link " to="investor-leads" onClick={onClose}>
            <Badge
              badgeContent={count}
              variant="dot"
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <AttachMoney className="nav_icon" />
            </Badge>
            <p style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              Investor List{" "}
              {count > 0 && (
                <Alert
                  severity="info"
                  variant="filled"
                  sx={{ padding: "2px 5px", fontSize: "12px" }}
                  icon={false}
                >
                  Select your BPO List
                </Alert>
              )}
            </p>
          </NavLink>
          <NavLink className="nav_link " to="agent-leads" onClick={onClose}>
            <Badge
              badgeContent={count}
              variant="dot"
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <AttachMoney className="nav_icon" />
            </Badge>
            <p style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              Agent List{" "}
              {count > 0 && (
                <Alert
                  severity="info"
                  variant="filled"
                  sx={{ padding: "2px 5px", fontSize: "12px" }}
                  icon={false}
                >
                  Select your BPO List
                </Alert>
              )}
            </p>
          </NavLink>
          <Lockable
            subscribed={
              type === "realty_agent" &&
              (premierAgentWebsite || dealAnalyzer || liveLeads || bpoFarm)
            }
            extLink={`${process.env.REACT_APP_BPOHOMESREALTY_URL}join`}
            title="Click to be become a realty agent"
          >
            <NavLink
              className="nav_link "
              to="homes-training-center"
              onClick={onClose}
              style={{
                pointerEvents: type === "realty_agent" ? "all" : "none",
              }}
            >
              <Style className="nav_icon" />
              <p>BPO Homes Training Center</p>
              {type !== "realty_agent" && (
                <LockIcon fontSize="16px" sx={{ ml: "auto" }} />
              )}
            </NavLink>
          </Lockable>
          {type !== "realty_agent" && (
            <Lockable
              subscribed={
                premierAgentWebsite || dealAnalyzer || liveLeads || bpoFarm
              }
              link="/available-territories"
              title="Click to buy a farm"
            >
              <NavLink
                className="nav_link "
                to="tech-training-center"
                onClick={onClose}
              >
                <Style className="nav_icon" />
                <p>BPO Tech Training Center</p>
                {!(
                  premierAgentWebsite ||
                  dealAnalyzer ||
                  liveLeads ||
                  bpoFarm
                ) && <LockIcon fontSize="16px" sx={{ ml: "auto" }} />}
              </NavLink>
            </Lockable>
          )}
          <Lockable
            title="Click to be become a plan"
            link="/prem-sub"
            subscribed={
              premierAgentWebsite || dealAnalyzer || liveLeads || bpoFarm
            }
          >
            <div
              className="nav_link "
              onClick={handleClick}
              rel="noreferrer"
              target="_blank"
            >
              <Storefront className="nav_icon" />
              <p>Marketing Center</p>
              {!(
                premierAgentWebsite ||
                dealAnalyzer ||
                liveLeads ||
                bpoFarm
              ) && <LockIcon fontSize="16px" sx={{ ml: "auto" }} />}
            </div>
          </Lockable>
          <Lockable
            title="Click to be become a plan"
            link="/prem-sub"
            subscribed={
              premierAgentWebsite || dealAnalyzer || liveLeads || bpoFarm
            }
          >
            <div
              className="nav_link "
              onClick={handleMBAClick}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: "2rem" }}
            >
              <Paid className="nav_icon" />
              <p>MDA Business Plan</p>
              {!(
                premierAgentWebsite ||
                dealAnalyzer ||
                liveLeads ||
                bpoFarm
              ) && <LockIcon fontSize="16px" sx={{ ml: "auto" }} />}
            </div>
          </Lockable>

          <div className="hr">
            <hr />
          </div>
        </div>
      </div>
    );
  }
};

export default AgentLeftNav;
